var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('mHeader'),_c('div',{staticClass:"neiron"},[_c('div',{staticClass:"main"},[_vm._m(0),_c('div',{staticClass:"logo-box"},[_c('SvgIcon',{staticStyle:{"font-size":"40.5333vw"},attrs:{"iconClass":"logo"}})],1),_c('div',{staticClass:"material"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("Order No.")]),_c('p',[_vm._v(_vm._s(_vm.orderInfo.order_sn))])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("Payment")]),_c('p',[_vm._v(_vm._s(_vm.orderInfo.pay_type))])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("Payment Date")]),(_vm.orderInfo.pay_at)?_c('p',[_vm._v(" "+_vm._s(_vm.translateMonth(_vm.orderInfo.pay_at.split(" ")[0].split("-")[1]))+" "+_vm._s(_vm.orderInfo.pay_at.split(" ")[0].split("-")[2])+", "+_vm._s(_vm.orderInfo.pay_at.split(" ")[0].split("-")[0])+" "+_vm._s(_vm.orderInfo.pay_at.split(" ")[1])+" ")]):_vm._e()]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("Contact")]),_c('p',[_vm._v(_vm._s(_vm.orderInfo.username || "-"))])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("Email")]),_c('p',[_vm._v(_vm._s(_vm.orderInfo.email))])]),(
            _vm.orderInfo &&
            _vm.orderInfo.ticket_data &&
            _vm.orderInfo.ticket_data.length != 0
          )?_c('div',{staticClass:"item2"},[_c('p',[_vm._v("Moominvalley Park 1-Day Pass")]),(_vm.orderInfo.use_at)?_c('p',{staticStyle:{"margin-bottom":"18px"}},[_vm._v(" "+_vm._s(_vm.translateMonth(_vm.orderInfo.use_at.split(" ")[0].split("-")[1]))+" "+_vm._s(_vm.orderInfo.use_at.split(" ")[0].split("-")[2])+", "+_vm._s(_vm.orderInfo.use_at.split(" ")[0].split("-")[0])+" ")]):_vm._e(),_vm._l((_vm.orderInfo.ticket_data),function(item,index){return _c('div',{key:index},[_c('p',[(item.tickets_type == 1)?_c('span',[_vm._v("Adult")]):(item.tickets_type == 2)?_c('span',[_vm._v("Child")]):(item.tickets_type == 4)?_c('span',[_vm._v("Secondary Student")]):_vm._e(),_vm._v(" x"+_vm._s(item.num)+" ")]),_c('span',[_vm._v("￥"+_vm._s(_vm.setSumPrice(item.total_price, 1)))])])})],2):_vm._e(),(
            _vm.orderInfo &&
            _vm.orderInfo.night_data &&
            _vm.orderInfo.night_data.length != 0
          )?_c('div',{staticClass:"item2"},[_c('p',[_vm._v("Moominvalley Park Night Pass")]),(_vm.orderInfo.use_at)?_c('p',{staticStyle:{"margin-bottom":"18px"}},[_vm._v(" "+_vm._s(_vm.translateMonth(_vm.orderInfo.use_at.split(" ")[0].split("-")[1]))+" "+_vm._s(_vm.orderInfo.use_at.split(" ")[0].split("-")[2])+", "+_vm._s(_vm.orderInfo.use_at.split(" ")[0].split("-")[0])+" ")]):_vm._e(),_vm._l((_vm.orderInfo.night_data),function(item,index){return _c('div',{key:index},[_c('p',[(item.tickets_type == 1)?_c('span',[_vm._v("Adult")]):(item.tickets_type == 2)?_c('span',[_vm._v("Child")]):(item.tickets_type == 4)?_c('span',[_vm._v("Secondary Student")]):_vm._e(),_vm._v(" x"+_vm._s(item.num)+" ")]),_c('span',[_vm._v("￥"+_vm._s(_vm.setSumPrice(item.total_price, 1)))])])})],2):_vm._e(),_c('div',{staticClass:"item2 item3"},[_c('div',[_c('p',[_vm._v("Total")]),_c('span',[_vm._v("￥"+_vm._s(_vm.setSumPrice(_vm.totalPrices, 1) || "-"))])])])])]),(_vm.orderInfo.order_codes && _vm.orderInfo.order_codes.length)?_c('div',{staticClass:"download-code"},[_vm._m(1),_vm._l((_vm.orderInfo.order_codes),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-left"},[_vm._v(" No."+_vm._s(item.code.replace(/(.{4}).*(.{4})/, "$1****$2"))+" ")]),_c('div',{class:[
            'item-right',
            { xiazai: item.status == '1' },
            { chuli: item.status != '1' },
          ]},[_c('el-button',{on:{"click":function($event){return _vm.infiniteRightBtn(item.url)}}},[_vm._v(_vm._s(item.status == "1" ? "Download" : "Processing"))])],1)])})],2):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit"},[_c('i',{staticClass:"dagou"},[_vm._v("✔")]),_vm._v(" Order Completed")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-code-tit"},[_c('div',{staticClass:"icon-box"},[_c('i',{staticClass:"el-icon-bottom"})]),_vm._v(" Download Ticket ")])
}]

export { render, staticRenderFns }